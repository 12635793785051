.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #3d3935;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
}

.App-link {
  color: #61dafb;
}

div {
  font-size: calc(10px + 1vmin);
  margin-bottom: 20px;
}

.social-links a {
  font-size: 3rem; /* Ändra storleken på ikonerna */
  margin: 0 10px; /* Lägg till mellanrum mellan ikonerna */
}

.social-links a:first-child {
  margin-left: 0; /* Ta bort vänster marginal för den första ikonen */
}

.social-links a:last-child {
  margin-right: 0; /* Ta bort höger marginal för den sista ikonen */
}

h1 {
  font-size: 1.5em;
}